import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
    return http({
        url: http.adornUrl('/hbi/hbiprojectmenu/list'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/delete/${id}`),
        method: "post"
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/hbiprojectmenu/${!data.menuId ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

export function selectList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectmenu/select/${projectId}`),
        method: "get",
    })
}

// 列表获取
export function getPcList() {
    return http({
        url: http.adornUrl('/hbi/hbiprojectmenu/pcList'),
        method: 'get',
        params: http.adornParams()
    })
}