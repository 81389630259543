<template>
    <div class="mod-config">
        <div class="wxts_msg_search">
            <span>配置菜单--{{projectName}}</span>
            <div class="f_t">
                <el-button-group>
                    <el-button type="primary" @click="deployMenu()" :loading="dataListLoading">刷新</el-button>
                    <el-button v-if="isAuth('hbi:hbiprojectmenu:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
                    <el-button @click="cancelHandle()" :loading="isOnSubmit">返回</el-button>
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border style="width: 100%;" v-loading="dataListLoading">
            <table-tree-column prop="name" header-align="center" treeKey="menuId"  label="菜单名称">
            </table-tree-column>
            <el-table-column prop="parentName" header-align="center" align="center"  label="上级菜单">
            </el-table-column>
           <!-- <el-table-column header-align="center" align="center"  label="图标">
                <template slot-scope="scope">
                    <icon-svg :name="scope.row.icon || ''"></icon-svg>
                </template>
            </el-table-column>-->
            <el-table-column prop="reportName" header-align="center" align="center"  label="报表名称">
            </el-table-column>
            <el-table-column prop="type" header-align="center" align="center"  label="类型">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
                    <el-tag v-else-if="scope.row.type === 1" size="small" type="success">菜单</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="orderNum" header-align="center" align="center"  label="排序号">
            </el-table-column>
            <el-table-column prop="enabled" header-align="center" align="center"
                             :show-overflow-tooltip="true" label="启用">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.enabled === 1" size="small" type="success"
                    >启用
                    </el-tag
                    >
                    <el-tag v-else size="small" type="info">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="isAuth('hbi:hbiprojectmenu:update')" type="text" class="update-button" size="small" @click="addOrUpdateHandle(scope.row.menuId)">修改</el-button>
                    <el-button v-if="isAuth('hbi:hbiprojectmenu:delete')" type="text" class="del-button" size="small" @click="deleteHandle(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="deployMenu"></add-or-update>
    </div>
</template>

<script>
    import AddOrUpdate from './addMenu'
    import {getList, deleteData} from '@/api/hbi/project/menu.js'
    import TableTreeColumn from "@/components/table-tree-column";

    import {treeDataTranslate} from "@/utils/index";

    export default {
        data() {
            return {
                showButton: true,
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                dataList: [],
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false,
                dataForm: {
                    projectId: null,
                    menuName: '',
                },
                projectName: ""
            }
        },
        components: {
            AddOrUpdate, TableTreeColumn
        },
        methods: {
            //获取数据列表
            deployMenu(project) {
                if(project && project.id){
                    this.dataForm.projectId = project.id
                    this.projectName = project.name
                }
                this.dataListLoading = true;
                const params_ = {
                    'projectId': this.dataForm.projectId
                }
                getList(params_).then(({data}) => {
                    if (data && data.code == 0) {
                        const menuList = data.data;
                        this.dataList = treeDataTranslate(menuList, "menuId");
                    }
                }).finally(() => {
                    this.dataListLoading = false;
                });
            },


            /**
             * 返回上一页
             */
            cancelHandle() {
                this.backMainPage(false);
            },
            /**
             * 关闭当前页返回上一页
             * @param refresh 返回后是否刷新列表
             */
            backMainPage(refresh) {
                this.$emit('refreshDataList', refresh)
            },

            // 新增 / 修改
            addOrUpdateHandle(id) {
                this.addOrUpdateVisible = true;
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id,this.dataForm.projectId);
                });
            },

            // 删除
            deleteHandle(row) {
                const id = row.menuId;

                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteData(id).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.deployMenu()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.dataListLoading = false
                    })
                }).catch(() => {
                })
            },
        }
    }
</script>
